import React from "react";

//importing required images
import mechanicIcon from "../../images/svg/icn_tools.svg";
import cancelIcon from "../../images/svg/icn_close.svg"


//JSX help button to create and cancel work orders
export default function HelpButton({
  activeWorkOrder,
  setShowLocationModal,
  cancelWorkOrder
}) {


  //===================================JSX RETURN===================================//

  return activeWorkOrder ? (
    <div className="bottom-nav d-flex justify-content-center align-items-end flex-row p-3 w-100">
      <button
        id="cancel-button"
        onClick={() => {
          cancelWorkOrder()
        }}
        className="help active"
      >
          <img src={cancelIcon} alt='close icon'></img>
        </button>
    </div>
  ) : (
    <div className="bottom-nav d-flex justify-content-center align-items-end flex-row p-3 w-100">
      <button
        id="help-button"
        onClick={() => {
          setShowLocationModal(true)
        }}
        className="help"
      >
        <span className="button-text poppins-bold" style={{fontSize: "32px", color: "white", textShadow: "1px 1px 0 black, -1px 1px 0 black, 1px -1px 0 black, -1px -1px 0 black"}}>Help!</span>
        {/* <img src={mechanicIcon} alt="mechanic icon"></img> */}
      </button>
    </div>
  );
}
