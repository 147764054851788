import React, {useState} from 'react'
import { Row, Col } from "react-bootstrap";
import baseUserIcon from "../../images/user-icon.png";
import { Background } from 'react-parallax';
import { handleMouseOver,handleMouseOut,handleMouseOutGreen } from '../../shared/sharedFunctions';
import UserEditModal from './UserEditModal';

export default function UserColumn({userData, handleLogout, getUserInformation, isMobile}) {

    const [showEditModal, setShowEditModal] = useState()



  return (
    <div style={{
        flexDirection: "column", 
        marginRight: isMobile ? "0px" : "15px",
        marginBottom:"10px"}}>

        <UserEditModal 
            showEditModal={showEditModal}
            setShowEditModal={setShowEditModal}
            userData={userData}
            getUserInformation={getUserInformation}/>

        {userData.profile_picture ? (
                <img
                    src={userData.profile_picture}
                    style={{
                        borderRadius: "50%",
                        height: "250px", // Set height and width to the same value
                        width: "250px",
                        border: "1px solid #FFAE46",
                        objectFit: "cover" // Ensures image maintains aspect ratio and covers the square
                    }}  
                    alt="user icon"
                />
            ) : (
                <img
                    src={baseUserIcon}
                    style={{
                        borderRadius: "50%",
                        height: "250px", // Set height and width to the same value
                        width: "250px",
                        border: "1px solid #FFAE46",
                        objectFit: "cover" // Ensures image maintains aspect ratio and covers the square
                    }} 
                    alt="user icon"
                /> 
        )}

        <div className="iconAndUsernameField">
            <div className="userNameContainer">
              <Row className="accountUserSubName poppins-extralight">
                User Name
              </Row>
              <Row className="accountUserName poppins-medium">
                {userData.user_name}
              </Row>
            </div>
        </div>

        <button
            onClick={() => {setShowEditModal(true);
            }}
            className="btn"
            style={{
                backgroundColor:"#FFAE46", 
                width:"100%", 
                marginBottom:"5px", 
                marginTop:"5px",
                color:"white",
                border:"1px solid #FFAE46"
            }}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            Edit Account Information
        </button>

        <div className="accountUserInformationContainer">
          <div className="accountUserInfoSubText poppins-extralight">
            Name
          </div>
          <div className="accountUserInfoText poppins-regular">
            {userData.name}
          </div>
          <div className="accountUserInfoSubText poppins-extralight">
            Mobile Phone Number
          </div>
          <div className="accountUserInfoText poppins-regular">
            {userData.phone}
          </div>
          <div className="accountUserInfoSubText poppins-extralight">
            Email
          </div>
          <div className="accountUserInfoText poppins-regular">
            {userData.email}
          </div>
          <div className="accountUserInfoSubText poppins-extralight">
            Account Plan
          </div>
          <div className="accountUserInfoText poppins-regular">
            {userData.account_plan}
          </div>
        </div>

        <button
            onClick={() => {
              console.log(" go to user edit page");
            }}
            className="btn"
            style={{
                backgroundColor:"#4D8C53", 
                width:"100%", 
                marginBottom:"5px", 
                marginTop:"5px",
                color:"white",
                border:"1px solid #4D8C53"
            }}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOutGreen}
          >
            Upgrade Plan
          </button>
        <div style={{marginTop:"10px"}} onClick={() => {handleLogout()}} className="accountLinkText poppins-medium">
          Log Out
        </div>

    </div>
  )
}
