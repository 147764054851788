import React from 'react'

import background from "../../images/palmtreesdark.png"

import ServiceHeader from '../ServicesComponenets/ServiceHeader'

import ParallaxSection from './Parallax'

import OurMission from './OurMission'

import Reports from './Reports'

import ServiceRibbon from './ServiceRibbon'

import AboutUs from './AboutUs'



export default function Landing() {


    

    return (
        
        <div 
            className="splash-screen d-flex justify-content-center align-items-center" 
            style={{ 
                backgroundImage: `url(${background})`, 
                minHeight: "100vh"
              }}>
    
          <ServiceHeader></ServiceHeader>

          <div
            style={{
                height: "100%",
                width: "100%",
                overflowY: "auto", 
                paddingBottom: "40px",
                scrollbarWidth: "none",
                '-ms-overflow-style': 'none'
              }}
            >

            <ParallaxSection></ParallaxSection>

            {/* <OurMission/> */}
            
            <Reports/>

            <ServiceRibbon/>

            <AboutUs/>

          </div>


                        
                        
        </div>
    )
}
