import { Parallax } from "react-parallax";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import background from "../../images/landing/the-net-st.pete.jpg"
import LogoBrand from '../../images/svg/logo_bike911.svg';
import {
  handleMouseOver,
  handleMouseOutGreen,
} from "../../shared/sharedFunctions"

const ParallaxSection = () => {
  const navigate = useNavigate();
  const [opacity, setOpacity] = useState(1);


  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const newOpacity = 1 - scrollPosition / 500; // Adjust the divisor for the fade-out speed
    setOpacity(newOpacity);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
        window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (

    <Parallax
      strength={500}
      style={{ 
        height: "100vh", 
        backgroundSize: "cover", 
        backgroundPosition: "center", 
        backgroundImage: `url(${background})`,
        WebkitMaskImage: "linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 15%)",
        maskImage: "linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 10%)"
        }} >


      <div className="herooverlay dark-5">
        {/* dark overlay [1 to 9 opacity] */}
      </div>

      <div className="container d-flex align-items-center justify-content-center vh-100">

        <div className="text-center position-relative" style={{ zIndex: 2 }}>
            <img src={LogoBrand} style={{width: '200px', animation: "fadeInUp 1s ease-in-out"}}/>
          <h1 className="hero-title display-1" style={{ color: "#FFAE46", opacity }}>
            BIKE911
          </h1>
          <p className="hero-subtitle display-4" style={{ color: "#4D8C53",fontWeight:"bold",animation: "fadeInUp 1s ease-in-out", opacity }}>
            Mobile Bike Mechanic And Retrieval
          </p>
            <p style={{
                color:"white",
                fontSize:"1rem",
                textAlign:"center",
                paddingLeft: "10%",
                paddingRight: "10%",
                animation: "fadeInUp 1s ease-in-out"}}>
                Welcome to Bike911, your on-demand mobile bicycle repair service. 
                Whether you’re at home, at work, or on a ride, our expert 
                mechanics come to you to fix any issues on the spot. We’re committed 
                to getting you back on the road quickly and safely, offering convenience 
                and quality in every service. Book your bike repair today and let us handle the rest!
            </p>

            <button
              className="btn"
              style={{
                backgroundColor:"#4D8C53",
                animation: "fadeInUp 1s ease-in-out"
              }}
              onClick={() => {navigate("/register")}}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOutGreen}
              >REGISTER AN ACCOUNT
            </button>
        </div>

      </div>
    </Parallax>
  );
};

export default ParallaxSection;
