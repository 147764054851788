import React, {useState} from 'react'

//importing react boostrap component
import Modal from "react-bootstrap/Modal";

//importing icons to use on page
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//importing ability to use cookies
import { useCookies } from "react-cookie";

//importing fetch request to update users account information
import { 
    updateUserInformation 
} from "../../fetchRequests/accountPageFetch";

//importing shared styling functions
import {
    handleMouseOver,
    handleMouseOut,
    handleMouseOutGreen,
    failToast,
    successToast
} from "../../shared/sharedFunctions"

export default function BikeEditModal({
    showBikeEditModal,
    setShowBikeEditModal}) {

    const [updateBikeData, setUpdateBikeData]=useState({
        model:"",
        brand:"",
        serial_number:"",
        date_added:"",
        tire_size:"",
        tube_size:"",
        bike_picture:""
    })

    const updateBikeDataFetch = async () => {
        console.log("FETCH FOR BIKE UPDATE: ", updateBikeData)
    }

    //function used for updating the 
    //account update form
    const updateForm = (value) => {
        return setUpdateBikeData((prev) => {
          return {...prev, ...value}
        })
      }

    const handleFileChange = (file) => {
        console.log(file)
        updateForm({ bike_picture: file });
        
    };

  return (
    <>
      <Modal
        size="sm-8"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showBikeEditModal}
        backdrop={false}
        keyboard={false}
        style={{
          maxWidth: "800px",
          maxHeight: "900px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div 
          className="modal-content" 
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)',}} 
        >

            <Modal.Header className="modalHeader">
                <div style={{textAlign:"left"}}>
                    <Modal.Title className="poppins-extrabold" style={{color:"#FFAE46"}}>
                    EDIT YOUR BIKE INFORMATION
                    </Modal.Title>
                </div>
                {/* canceling service order if modal is closed */}
                <div className="confirmModalCloseContainer">
                    <FontAwesomeIcon
                    className="modalCloseButton"
                    icon={faTimes}
                    color="grey"
                    onClick={() => {
                        //setMechanicInfo({ time: null, distance: null, name: null });
                        setShowBikeEditModal(false);
                    }}
                    />
                </div>
            </Modal.Header>
            
            <Modal.Body>
                <div style={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    textAlign:"center",
                    marginBottom:"10px"}}>
                    <div className="form w-100 d-grid gap-3 p-4" style={{maxWidth:"350px"}}>
                        <div className="poppins-bold" style={{color:"#FFAE46"}}>Brand</div>
                        <div className="">
                            <input
                                id="brand"
                                className="form-control"
                                type="text"
                                placeholder='brand'
                                value={updateBikeData.brand}
                                onChange={(e) => updateForm({brand: e.target.value})}>
                            </input>
                        </div>
                        <div className="poppins-bold" style={{color:"#FFAE46"}}>Model</div>
                        <div className="">
                            <input
                                id="model"
                                className="form-control"
                                type="text"
                                placeholder='model'
                                value={updateBikeData.model}
                                onChange={(e) => updateForm({model: e.target.value})}>
                            </input>
                        </div>
                        <div className="poppins-bold" style={{color:"#FFAE46"}}>Tire Size</div>
                        <div className="">
                            <input
                                id="tire_size"
                                className="form-control"
                                type="text"
                                placeholder='tire_size'
                                value={updateBikeData.tire_size}
                                onChange={(e) => updateForm({tire_size: e.target.value})}
                                >
                            </input>
                        </div>
                        <div className="poppins-bold" style={{color:"#FFAE46"}}>Tube Size</div>
                        <div className="">
                            <input
                                id="tube_size"
                                className="form-control"
                                type="text"
                                placeholder='tube_size'
                                value={updateBikeData.tube_size}
                                onChange={(e) => updateForm({tube_size: e.target.value})}
                                >
                            </input>
                        </div>
                        <div className="poppins-bold" style={{color:"#FFAE46"}}>Serial Number</div>
                        <div className="">
                            <input
                                id="serial_number"
                                className="form-control"
                                type="text"
                                placeholder='serial_number'
                                value={updateBikeData.serial_number}
                                onChange={(e) => updateForm({serial_number: e.target.value})}
                                >
                            </input>
                        </div>
                        <div className="">
                            <label htmlFor="image" className="poppins-bold" style={{color:"#FFAE46"}}>Bike Picture</label>
                            <input
                                id="image"
                                className="form-control"
                                type="file"
                                accept="image/*" // Restricts the file selection to image types
                                onChange={(e) => handleFileChange(e.target.files[0])} // A function to handle the file upload
                            />
                        </div>
                        <button
                            className="btn"
                            style={{backgroundColor:"#4D8C53",color:"white",border:"1px solid #4D8C53"}}
                            onClick={() => {updateBikeDataFetch()}}
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOutGreen}
                            type="button">Submit Information
                        </button>
                        <button
                            className="btn"
                            style={{backgroundColor:"#FFAE46",color:"white",border:"1px solid #FFAE46"}}
                            onClick={() => {setShowBikeEditModal(false)}}
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                            type="button">Cancel
                        </button>
                    </div>
                </div>
          </Modal.Body>  
        </div>
      </Modal>
    </>
  )
}
