import bikeTools from '../../images/services/bike-tools.jpeg';
import bikeBattery from '../../images/services/bike-battery.webp';
import bikeBrakes from '../../images/services/bike-brakes.webp';
import bikeRelax from '../../images/services/bike-relax.webp';
import bikeWheel from '../../images/services/bike-wheel.jpg';
import newBike from '../../images/services/new-bike.jpg';


export const cardData = [
    {
      title:"GENERAL TUNE-UP", 

      text:`Keep your bike in top shape or revive your old ride with Bike911! 
      Our comprehensive tune-up service includes a thorough cleaning and inspection of your bike's frame and components. 
      We'll adjust the hub bearings, headset, and bottom bracket for optimal performance. 
      Our skilled technicians will ensure your wheels are properly tensioned and true, 
      and we'll fine-tune your brakes and shifting for smooth operation. 
      We also provide lubrication for essential components like the chain and cables, and we meticulously check all 
      fasteners to ensure they're tightened to the right torque. Please note that replacing worn or broken parts 
      will incur additional costs, including parts and labor.`, 

      price:`PRICE: $75`,

      member_price:`50% OFF FOR MEMBERS ($38)`,

      image: bikeTools,

      image_position: "left"
    },
    {
      title:"ASSEMBLING YOUR NEW BIKE", 

      text:`Ensure your new E-bike or traditional bike is expertly assembled by Bike911! 
      Professional assembly guarantees that all components are not only installed correctly 
      but also adjusted for optimal performance. This attention to detail can significantly 
      enhance your bike’s performance and extend its lifespan. Additionally, manufacturers appreciate 
      knowing that a bike has been professionally assembled, which can be crucial in the event of 
      warranty claims or service issues.`, 

      price:"PRICE: $120",

      member_price:`50% OFF FOR MEMBERS ($60)`,

      image:newBike,

      image_position: "right"
    },
    {
      title:"BRAKE MAINTENANCE", 

      text:`Brakes are essential for your safety and bike performance. 
      Worn or rubbing brakes can significantly impact your ride, compromising both 
      efficiency and safety. At Bike911, we ensure that your brakes are properly adjusted, 
      enhancing your bike’s stopping power and allowing you to travel further with greater ease.`, 

      price:`PRICE: $15 Per Brake`,

      member_price:`50% OFF FOR MEMBERS ($8 Per Brake)`,

      image:bikeBrakes,

      image_position: "left"
    },
    {
      title:"E-BIKE ELECTRICAL DIAGNOSIS AND INSTILLATION", 

      text:`Experiencing issues with the power or electronic components of your E-bike? 
      At Bike911, we’ll diagnose the problem for you. If you've already identified 
      the issue with the manufacturer and have the replacement components on hand, 
      we’re here to assist with the installation. Contact us today for an estimate!`, 

      price:"PRICE: CALL FOR ESTIMATE",

      image:bikeBattery,

      image_position: "right"
    },
    {
      title:"WHEEL TRUING AND SPOKE ADJUSTEMENT", 

      text:`Wheels can become "out of true" or develop side-to-side movement during rotation, 
      which may occur gradually or as a result of sudden impact. This issue can negatively 
      impact both braking performance and overall wheel strength. It's advisable to have your 
      spokes adjusted to straighten and reinforce your wheels when this happens. For motor hub wheels, 
      it’s often more cost-effective to rebuild the motor onto a new rim and spokes rather than 
      replacing the entire wheel. Bike911 is here to help—call us for an estimate!`, 

      price:"PRICE: CALL FOR ESTIMATE",
      
      image:bikeWheel,

      image_position: "left"
    },
    {
      title:"OTHER BIKE SERVICES", 

      text:`In addition to tube replacements and shifting adjustments, we offer a wide range of 
      other services tailored to your needs. Simply give us a call, and we’ll be happy to 
      discuss your specific situation and provide you with pricing details.`, 
      
      price:"PRICE: CALL FOR ESTIMATE",

      image:bikeRelax,

      image_position: "right"
    }
  ]