import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";

//font awesome icons
import { faCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//images used on page
import baseUserIcon from "../../images/user-icon.png";
import background from "../../images/palmtreesdark.png";

//importing the logo component
import Logo from '../Logo';

//importing use cookie to process browser cookies
import { useCookies } from "react-cookie";

//importing child modal component
import Footer from '../HomePageComponents/Footer.jsx';
import UserColumn from "./UserColumn.jsx";
import BikeColumn from "./BikeColumn.jsx";
import Header from "../HeaderComponents/Header.jsx";

//importing fetch function
import { retrieveUserInformation } from "../../fetchRequests/accountPageFetch.js";

//importing shared styling function
import {
  failToast,
} from "../../shared/sharedFunctions.js"

//account page component where the user can see their account information
export default function AccountPage({workOrderData}) {
  //ability to navigate
  const navigate = useNavigate();
  //ability to use browser cookies
  const [cookie, setCookie, removeCookie] = useCookies();

  //use state for containing user account information
  const [userData, setUserData] = useState({
    name:"",
    user_name:"",
    phone:"",
    email:"",
    account_plan:"",
    bike:{
      brand:"",
      model:"",
    },
    profile_picture:""
  })

  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
  };

  useEffect(() => {
      handleResize(); // Check on initial render
      window.addEventListener('resize', handleResize); // Check on resize

      return () => window.removeEventListener('resize', handleResize); // Cleanup on unmount
  }, []);

  //===================================GETTING USER ACCOUNT INFORMATION===================================//

  const getUserInformation = async () => {

    //calling function to fetch user data
    const serverResponse = await retrieveUserInformation(cookie.accessToken)

    if(!serverResponse.data){
      failToast(serverResponse.message)
      //navigate("/")
      return
    }

    //parsing recieved data
    const dataFromFetch = serverResponse.data.user_data
    const {name, user_name, phone, email, account_plan, bike,profile_picture} = dataFromFetch

    //updating state to contain users retrieved data
    setUserData({
      name:name,
      user_name:user_name,
      phone:phone,
      email:email,
      account_plan:account_plan,
        bike:{
        brand:bike.brand,
        model:bike.model,
      },
      profile_picture:profile_picture
    })
  }
  
  //calling get user information on page load
  useEffect(() =>{
    getUserInformation()
  }, [])


  //===================================COMPONENT FUNCTIONS===================================//

  //called if user presses logout button
  const handleLogout = () => {
    removeCookie("token");
    navigate("/login");
  }


  //===================================JSX RETURN===================================//

  return (
    <div
      className="accountPageContainer"
      style={{ backgroundImage: `url(${background})`, minHeight: "100vh" }}
    >
      
      <Header workOrderData={workOrderData}/>

      <div style={{
        minHeight: "80vh",
        width: "100%",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center",            // Center items vertically
        justifyContent: "center",         // Center items horizontally
        paddingTop: isMobile ? "600px" : "0px",
        overflowY: "auto"
        }}>
            
        <UserColumn 
          userData={userData} 
          handleLogout={handleLogout}
          getUserInformation={getUserInformation}
          isMobile={isMobile}
        />

        <BikeColumn 
          userData={userData}
          isMobile={isMobile}
        />
        
      </div>
      <Footer></Footer>
    </div>
  );
}
