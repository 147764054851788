import React from 'react'
import HazardSign from "../../images/under-construction/hazard-sign.png"

export default function ConstructionBanner() {
  return (
    <div style={{ 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        width: '500px',
        height: '80px',
        margin: '0 auto'  // Centers the banner container
    }}>

        {/* Background image */}
        <img src={HazardSign} style={{ height: '55px'}} />
        
        {/* Text overlay */}
        <div style={{
            color: 'white',
            fontSize: '15px',
            fontWeight: 'bold',
            textAlign: 'center',
            whiteSpace:"nowrap"
        }}>
            Website is currently under construction <br/> all content is subject to change
        </div>
        
        <img src={HazardSign} style={{ height: '55px'}} />

    </div>
  )
}
