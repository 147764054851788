import brentPic from "../../../images/landing/aboutus/Brent.jpg"
import brianPic from "../../../images/landing/aboutus/Brian.webp"
import wolfePic from "../../../images/landing/aboutus/Wolfe.jpeg"


export const data = [
    {
        name:"Brent Bruns",
        title:"Owner & Founder",
        image:brentPic,
        description:"",
        image_position:"left"
    },
    {
        name:"Brian Peret",
        title:"CTO & Co-Founder",
        image:brianPic,
        description:"",
        image_position:"right"
    },
    {
        name:"Max Wolfe",
        title:"Lead Developer",
        image:wolfePic,
        description:`Moved to Florida in 2017 after graduation with the goal of becoming a certified master scuba diver. 
                Began work as a hull diver cleaning the bottoms of boats and performing basic maintenance. 
                Later became a dockhand then dock manager earning a 25 ton master captain's license. 
                Left the docks and became a charter boat captain, after a charter through the Bahamas 
                came back to Florida and enrolled in a full stack software development school. 
                Upon graduation became a coach at the same school and became the sole developer for Bike911`,
        image_position:"left"
    }
]