import React, {useState, useEffect} from 'react'

//importing shared styling functions
import {
    handleMouseOver,
    handleMouseOut,
    handleMouseOutGreen,
    failToast,
    successToast
} from "../../shared/sharedFunctions"

import { getCurrentUserLocationFetch } from "../../fetchRequests/workOrderFetches";

import { useCookies } from "react-cookie";

import LoadingModal from './LoadingModal';

//importing styling boostrap components
import Modal from "react-bootstrap/Modal";

//importing icons for component
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ConfirmLocationModal({
    usersUpdatedLocation,
    showLocationModal,
    setShowLocationModal,
    setShowWorkDetailsModal}) {

    //ability to use cookies
    const [cookie, setCookie, removeCookie] = useCookies();
    const [possibleLocation, setPossibleLocation] = useState(null)
    const [loading, setLoading] = useState(false)


    const getUserLocation = async () => {

        setLoading(true)
    
        const locationSent = {
          updateLat: usersUpdatedLocation.lat,
          updateLng: usersUpdatedLocation.lng,
        }
    
        //calling function which will send fetch to create a new work order
        const serverResponse = await getCurrentUserLocationFetch(cookie.accessToken, locationSent)
                  
        setLoading(false)

        console.log(serverResponse)
    
        if(!serverResponse.data){
          failToast(serverResponse.message)
          setShowLocationModal(false)
          return
        }
    
        
        setPossibleLocation(serverResponse.data)
        return
    };

    useEffect(() => {
        getUserLocation()
    }, [])


    const handleLocationConfirm = () => {
        successToast("Location has been confirmed, thank you")
        setShowLocationModal(false)
        setShowWorkDetailsModal(true)
    }

    const handleIncorrectLocation = () => {
        getUserLocation()
    }

  return (
    <>
      {loading ? (
          <LoadingModal message={"Retrieving your precise location..."}/>
        ) : (
        <Modal
          size="sm-8"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showLocationModal}
          backdrop={false}
          keyboard={false}
          style={{
            maxWidth: "500px",
            maxHeight: "600px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >

        <div className="modal-content" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)'}} >

            
            <Modal.Header className="modalHeader">
                    <div style={{textAlign:"center"}}>
                        <Modal.Title className="poppins-extrabold" style={{color:"#FFAE46"}}>
                            CONFIRM LOCATION
                        </Modal.Title>
                    </div>
                    {/* canceling service order if modal is closed */}
                    <div className="confirmModalCloseContainer">
                        <FontAwesomeIcon
                            className="modalCloseButton"
                            icon={faTimes}
                            color="black"
                            onClick={() => {
                                setShowLocationModal(false);
                        }}/>
                    </div>
            </Modal.Header>
            <Modal.Body>
                <div style={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    textAlign:"center",
                    marginBottom:"10px"}}>
                    <div className="form w-100 d-grid gap-3 p-4" style={{maxWidth:"350px"}}>

                    <p style={{color:"white"}}>
                        In order for our mechanics to assist we need to confirm your location
                    </p>

                    <p style={{color:"white"}}>Is your current location: 
                        <p style={{color:"#FFAE46", fontWeight:"bold"}}>
                            {possibleLocation}
                        </p>
                    </p>

                    <button
                        className="btn"
                        style={{
                            backgroundColor:"#4D8C53",
                            color:"white",
                            border: "1px solid black",
                            textShadow: "1px 1px 2px black",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
                          }}
                        onClick={() => {handleLocationConfirm()}}
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOutGreen}
                        type="button">Yes that is my current location
                    </button>
                    <button
                        className="btn"
                        style={{
                            backgroundColor:"#FFAE46",
                            color:"white",
                            border: "1px solid black",
                            textShadow: "1px 1px 2px black",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
                          }}
                        onClick={() => {handleIncorrectLocation()}}
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                        type="button">No that location is not accurate
                    </button>
                </div>
                </div>
            </Modal.Body>
            </div>
        </Modal>
        )}
    </>
  )
}
